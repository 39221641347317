body .react-date-picker__inputGroup {
  @apply p-0;
}

body .react-date-picker__inputGroup__input {
  @apply text-sm;
  @apply py-2.5;
  @apply box-border;
  @apply px-0;
}

body .react-date-picker__inputGroup__input:focus {
  @apply outline-none;
}

body .react-date-picker__wrapper {
  @apply min-w-full;
  @apply rounded;
  @apply border-primary;
  @apply border-gray-300;
  @apply px-3;
}

body
  .react-calendar__month-view__days__day--weekend:not(.react-calendar__tile--active) {
  @apply text-neutral;
}

body .react-date-picker__inputGroup__divider {
  @apply px-1;
}

body .react-date-picker__clear-button {
  @apply hidden;
}

body .react-date-picker--open .react-date-picker__wrapper {
  @apply border-primary;
  box-shadow: 0 0 0 1px rgb(29, 121, 242) !important;
}

body .react-date-picker__calendar-button {
  @apply relative;
  @apply -right-2;
  width: 34px;
  @apply text-primary-secText;
}

body .react-date-picker__calendar-button:focus {
  @apply outline-none;
}

body .react-calendar__month-view__days__day {
  @apply w-9;
  @apply h-9;
  @apply rounded-full;
  flex-basis: 36px !important;
  max-width: 36px !important;
  padding: 0;
  transition: all 0.03s ease-out;
}

body .react-calendar__month-view__days__day.react-calendar__tile--active {
  transition-delay: 0.15s;
}

body .react-calendar__month-view__weekdays__weekday {
  @apply w-9;
  @apply h-9;
  flex-basis: 36px !important;
  max-width: 36px !important;
  font-weight: 400;
  text-transform: capitalize;
}

body
  .react-calendar__month-view__days__day:enabled:not(.react-calendar__tile--active):hover {
  @apply transition;
  @apply bg-gray-100;
}

body .react-date-picker__calendar {
  width: 292px;
}

body .react-calendar__month-view__days {
  @apply px-5;
  @apply pb-5;
}

body .react-calendar__month-view__weekdays {
  @apply px-5;
}

body .react-calendar__month-view__weekdays__weekday abbr[title] {
  text-decoration: none;
}

body .react-date-picker__calendar .react-calendar {
  @apply border-0;
  @apply shadow;
  @apply rounded-md;
  @apply bg-white;
}

body .react-calendar__year-view .react-calendar__tile,
body .react-calendar__decade-view .react-calendar__tile,
body .react-calendar__century-view .react-calendar__tile {
  @apply p-3;
}

body .react-calendar__tile--now {
  @apply bg-primary-100;
  @apply text-black;
}

body .react-calendar__tile--now.react-calendar__tile--active:hover {
  @apply text-white;
}

body .react-calendar__navigation button:enabled:hover,
body .react-calendar__navigation button:enabled:focus {
  @apply bg-gray-100;
}

body .react-calendar__navigation {
  @apply h-12;
  @apply pt-5;
  @apply px-3;
  @apply mb-5;
}

body .react-calendar__navigation button {
  @apply min-w-0;
  @apply w-9;
}

body .react-calendar__navigation__prev2-button,
body .react-calendar__navigation__next2-button {
  @apply hidden;
}

body .react-calendar__navigation__arrow {
  @apply rounded-md;
}

body .react-calendar__tile:disabled {
  background-color: transparent;
  opacity: 0.5;
  cursor: default;
}
