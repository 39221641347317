body .rdrDateRangePickerWrapper {
  flex-direction: row-reverse;
  /* box-shadow: 0px 4px 20px rgb(5 45 97 / 12%); */
  border-radius: 4px;
}

body .rdrStaticRangeLabel {
  color: #052d61;
  font-size: 14px;
  padding: 8px;
}

body .rdrInputRanges {
  display: none;
}

body .rdrMonthAndYearWrapper {
  padding-top: 0;
  margin-top: 24px;
  height: auto;
}

body .rdrDefinedRangesWrapper {
  border-left: solid 1px #e0e5eb;
  border-right: 0;
  padding: 0 24px;
  width: 164px;
  margin-top: 92px;
}

body .rdrDateDisplayWrapper {
  background-color: transparent;
  width: calc(100% + 164px);
}

body .rdrDateDisplay {
  display: inline-flex;
  width: 100%;
  justify-content: flex-start;
  border-bottom: 1px solid #e0e5eb;
  margin: 0;
  padding: 16px 24px;
}

body .rdrDateDisplayItem {
  border: 1px solid #e0e5eb;
  box-shadow: none;
  width: auto;
  flex: none;
}

body .rdrDateDisplayItem input {
  height: 34px;
  width: 96px;
}

body .rdrMonthAndYearPickers select {
  padding: 8px 24px 8px 12px;
  border-radius: 4px;
  border: 1px solid #e0e5eb;
  font-size: 14px;
}

body .rdrStaticRange {
  margin-bottom: 8px;
  border-bottom: 8px;
  border-radius: 4px;
  transition: all 0.15s ease-in;
}

body .rdrStaticRange:focus,
body .rdrStaticRange:active {
  box-shadow: rgb(33 145 242 / 25%) 0px 0px 0px 0.25em;
}

body .rdrDefinedRangesWrapper .rdrStaticRangeSelected {
  font-weight: 400;
}

body .rdrStaticRanges {
  display: inline-flex;
}

body .rdrStaticRange.rdrStaticRangeSelected {
  background: #f1f6fe;
}

body .rdrMonths {
  margin-top: 24px;
}

body .rdrMonthName {
  padding-top: 0;
}

body .rdrMonth {
  padding-bottom: 0;
}

body .rdrDay {
  height: 35px;
  margin-bottom: 2px;
}

body .rdrDayStartPreview,
body .rdrDayInPreview,
body .rdrDayEndPreview {
  border: 0;
  transition: background-color 0.15s ease-in;
  background-color: rgba(29, 121, 242, 0.12);
  height: 100%;
  top: 0;
}

body .rdrStartEdge {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

body .rdrEndEdge {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

body .rdrStartEdge.rdrEndEdge {
  border-radius: 20px;
}

body .rdrDayNumber,
body .rdrSelected,
body .rdrInRange,
body .rdrStartEdge,
body .rdrEndEdge {
  top: 0;
  bottom: unset;
  height: 100%;
}

body .rdrNextPrevButton,
body .rdrNextPrevButton:hover {
  background-image: url("../../assets/left-arrow.svg");
  background-repeat: no-repeat;
  background-position: center;
}

body .rdrNextPrevButton {
  background-color: transparent;
  transition: all 0.15s ease-in;
}

body .rdrNextPrevButton:hover {
  background-color: #eff2f7;
}

body .rdrNextPrevButton i {
  display: none;
}

body .rdrNextButton {
  transform: rotate(180deg);
}

body .rdrStaticRange.rdrStaticRangeSelected span {
  color: #1d79f2;
  font-weight: 400;
}

body .rdrStaticRange:hover .rdrStaticRangeLabel,
body .rdrStaticRange:focus .rdrStaticRangeLabel {
  background-color: transparent;
}

body .rdrStaticRange:hover {
  background: #f1f6fe;
}

body .rdrDayNumber span {
  font-size: 14px;
  color: #052d61;
}

body .rdrDayPassive .rdrDayNumber span {
  color: #667c99;
}

body .rdrStartEdge {
  color: #1d79f2 !important;
}

body .rdrInRange {
  color: rgba(29, 121, 242, 0.12) !important;
}
body .rdrInRange + .rdrDayNumber span {
  color: #052d61 !important;
}

body .rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span {
  color: #052d61 !important;
}
