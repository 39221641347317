@import url("./components/react-date-picker-style.css");
@import url("./components/react-select-style.css");
@import url("./components/react-toastify.css");
@import url("./components/react-date-range-style.css");
@import url("./components/feedback-emojis-style.css");
@import url("./costumized-style.css");

@tailwind base;
@tailwind components;
@tailwind utilities;
